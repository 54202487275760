.contact{
    /*background: url('/public/images/img-cliff.jpg') center center/cover no-repeat;*/
    background-color: var(--background-tertiary-color);
    width: 100%;
    padding: 4rem;
    padding-top: 0;
}

.contact h1{
    color: var(--color-on-tertiary);
    text-align: center;
}

.contact_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4rem;
}

.contact_item_link{
    list-style: none; 
    margin: 1rem 2rem;
    font-size: 1.2rem;
    text-decoration: none;
    max-width: 6rem;
    height: 6rem;
    color: var(--color-on-tertiary);
    background-color: var(--background-tertiary-color);
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 20px;
    
    box-shadow: 0 6px 20px rgba(22, 23, 25, 0.95);
    border-width: 1.5rem;
    border-style: solid;
    border-color: var(--background-tertiary-color);   
    transition: all 0.5s ease-out;
    z-index: 4;
}

body.dark .contact_item_link{
    box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.1);
}

.contact_item_link i{
    margin-right: 1rem;
    font-size: 3rem;
}


.contact_item_link:hover{
    max-width: 25rem;
    transition: all 1s ease-out;
}

.fa-solid {
    margin-left : 0rem;
}

.bar_contact{
    height: 8vw;
    width: 100%;
    margin: -4vw 0px -4vw 0px;
    transform: rotate(-4deg) scale(1.2, 1);
    background-color: var(--background-tertiary-color);
}

@media screen and (max-width: 960px) {
    .contact_item_link:hover{
        max-width: 6rem;
        color: var(--color-on-primary);
        background-color: var(--background-primary-color);
        border-color: var(--background-primary-color);
        transition: all 0.5s ease-out;
    }
    .contact{
        padding: 4rem 2rem;
        padding-top: 0;
    }
}

@media screen and (max-width: 400px) {
    .contact{
        padding: 4rem 1rem;
        padding-top: 0;
    }
}