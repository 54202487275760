.language_selector{
    border-radius: 6px;
    margin-right: 1rem;
}

.language_selector img{
    width: 2rem;
    height: 2rem;
}

.language_selector_dropdown{
    width: 6rem;
    border-radius: 6px;
}

.country_option{
    display: block;
    width:6rem;
}

.dropdown_selector_language{
    display: block;
    width:1rem;
    height: 1rem;
}
