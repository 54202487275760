* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

body{
  -webkit-font-smoothing: antialiased;
  position: relative;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

:root{
  --background-primary-color: #242424;
  --background-secondary-color: #474747;
  --background-tertiary-color: #b1b1b1;
  --background-forth-color: #121212;
  --color-on-primary: #fff;
  --color-on-secondary: #fff;
  --color-on-tertiary: #242424;
  --cyan-color: #00b1ff;
  --dark-cyan-color: #006fba;
}
 
body.dark {
  --background-primary-color: #282323;
  --background-secondary-color: #202020;
  --background-tertiary-color: #242424;
  --color-on-primary: #fff;
  --color-on-secondary: #fff;
  --color-on-tertiary: #fff;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

h1 {
  text-align: center;
  color: var(--tertiary-color);
  font-size: 2.5rem;
  z-index: 2;
  position: relative;
}


/* Allow smooth transition between dark and light mode */
p, h1{
  transition: color 0.35s ease-in-out;
}

div{
  transition: background-color 0.35s ease-in-out;
}

.slide_in_on_scroll{
  z-index: 11;
}