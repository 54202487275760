.projects {
    padding: 4rem;
    padding-bottom: 2rem;
    padding-top: 0;
    background: var(--background-secondary-color);
}


.projects_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.projects_wrapper {
    position: relative;
    margin: 50px 0 45px;
    width:100%;
}

.projects_items {
    margin-bottom: 24px;
}

.projects_item {
    position: relative;
    display: flex;
    margin: 1rem 1rem;
    border-radius: 10px;
    z-index: 3;
    background-color: var(--background-secondary-color) ;
}

.projects_item_container {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

body.dark .projects_item_container {
    box-shadow: 0 6px 20px rgba(255, 255, 255, 0.15);
}

.projects_item_pic-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.projects h1{
    padding-top: 0;
    color: var(--color-on-secondary);

}

.projects_item_pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: var(--color-on-primary);
    background-color: var(--background-primary-color);
    box-sizing: border-box;
}

.projects_item_img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}


.projects_item_info {
    padding: 20px 30px 30px;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    z-index: 3;
}


.projects_item_text, .language_item_link{
    color: var(--color-on-secondary);
    font-size: 18px;
    line-height: 24px;
}

.language_item_link{
    color : var(--cyan-color);
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-right: 1rem;
}

.projects_btns{
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    margin-top: 1rem;
    justify-content: center;
}

.btn_prj{
    font-size: 18px;
    background-color: transparent;
    color: var(--color-on-secondary);
    position: relative;
    padding: 0.5rem 1rem;
    border: 2px solid var(--color-on-secondary);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 3;
    margin: 0.5rem 1rem;
    backdrop-filter: blur(5px);
}

.btn_prj.active{
    background-color: var(--color-on-secondary);
    color: var(--background-secondary-color);
}

.btn_prj:hover{
    transition: all 0.3s ease-in-out;
}

.language_item_link:hover{
    color : var(--dark-cyan-color);
    transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 1200px) {
    .content_blog_container {
        width: 84%;
    }
}
@media only screen and (min-width: 1400px) {
    .projects_items {
        display: flex;
        flex-flow: row nowrap;
    }
}


@media only screen and (max-width: 1024px) {
    .projects_item {
        margin-bottom: 2rem;
    }
    .projects{
        padding: 1rem;
    }
}



.bar_projects{
    height: 8vw;
    width: 100%;
    margin: -4vw 0px -4vw 0px;
    transform: rotate(-4deg) scale(1.2, 1);
    background-color: var(--background-secondary-color);
    z-index: 1;
}