.navbar {
  background: linear-gradient(90deg, rgba(28, 27, 27, 0.5) 0%, rgba(26, 23, 23, 0.5) 100%);
  backdrop-filter: blur(8px);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  transition: top 0.3s;
}

body.dark .navbar {
  border-bottom: 1px solid var(--color-on-primary);
}

.logo_home_img {
  width: 45px;
  height: 45px;
  margin-bottom: 4px;
}

.logo_englobe {
  background: url('/public/images/logo.png') center center/cover no-repeat;
}

.navbar-logo>i {
  padding-left: 10px;
}

/* Nav hamburger */

#nav-icon {
  width: 50px;
  height: 30px;
  margin-top: 1rem;
  margin-right: 1.4rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}


#nav-icon span {
  display: block;
  position: absolute;
  height: 6px;
  width: 51%;
  background: var(--color-on-primary);
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon span:nth-child(1),
#nav-icon span:nth-child(2) {
  top: 0px;
}

#nav-icon span:nth-child(3),
#nav-icon span:nth-child(4) {
  top: 14px;
}

#nav-icon span:nth-child(5),
#nav-icon span:nth-child(6) {
  top: 28px;
}

#nav-icon.open span:nth-child(1),
#nav-icon.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2),
#nav-icon.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon.open span:nth-child(5) {
  left: 5px;
  top: 22px;
}

#nav-icon.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 22px;
}

/* End of nav hamburger */

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  max-width: 1120px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: var(--color-on-primary);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 0px solid var(--color-on-primary);
  transition: all 0.2s ease-out;
}



.fa-bars {
  color: var(--color-on-primary);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* Little thing to select language and dark mode */

.utility_container {
  position: absolute;
  top: calc(60px + 1rem);
  right: calc(-20rem + 2rem);
  width: 20rem;
  height: 4.50rem;
  border-radius: 2.25rem;
  border: 1px solid rgb(26, 23, 23);
  background-color: rgb(26, 23, 23);
  color: var(--color-on-primary);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  align-content: center;
  transition: all 0.3s ease-out;
}

.utility_container i {
  padding: 1rem 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.utility_container:hover {
  right: calc(-20rem + 14rem);
  transition: all 0.2s ease-out;
}

.utility_container:hover i {
  transform: rotate(180deg);
  transition: all 0.2s ease-out;
}

body.dark .utility_container {
  border: 1px solid #fff;
}

/* End of little thing to select language and dark mode */


.contact-button {
  text-decoration: none;
  color: var(--color-on-tertiary);
  padding: 8px 20px;
  border-radius: 15px;
  background: var(--background-tertiary-color);
  transition: .3s;

}

.contact-button:hover {
  box-shadow: 5px 5px var(--color-on-secondary);
  transition: .3s;
}


@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@media (min-width: 961px) {
  .nav-item {
    height: 60px;
  }

  .nav-links:hover {
    border-bottom: 4px solid var(--color-on-primary);
    transition: all 0.2s ease-out;
  }
}

@media screen and (max-width: 960px) {
  .navbar .btn-mobile {
    display: none;
  } 

  .logo_englobe {
    margin-top: 0.4rem;
    margin-left: 1rem;
    position: absolute;
    display: block;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: var(--color-on-primary);
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--color-on-primary);
    font-size: 2rem;
  }

  
 
  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: var(--color-on-primary);
    padding: 14px 20px;
    border: 1px solid var(--color-on-primary);
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: var(--background-tertiary-color);
    color: var(--color-on-tertiary);
    transition: 250ms;
  }
}