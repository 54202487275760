.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    color: var(--color-on-tertiary);
    border: 1px solid var(--background-tertiary-color);
    background: var(--background-tertiary-color);
    background-size: 0% 100%;
    background-position: -50% -50%;

    background-repeat: no-repeat;
    transition: all 0.35s;
}

body.dark .btn--primary{
    background-image: linear-gradient(#000,#000);
}

.btn--outline {
    color: var(--color-on-primary);
    padding : 8px 20px;
    border: 1px solid var(--background-tertiary-color);
    background: var(--background-tertiary-color);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: all 0.35s;
    text-decoration: none;
}

body.dark .btn--outline{
    background-image: linear-gradient(#000,#000);
}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--outline:hover,
.btn--outline:focus-visible{
    background-size: 0% 100%;
    color:var(--color-on-tertiary);
}

.btn--primary:hover,
.btn--primary:focus-visible{
    background-size: 100% 100%;
    color : var(--color-on-primary);
}

/* body.dark .btn--primary:hover,
body.dark .btn--primary:focus-visible{
    background-size: 100% 100%;
    color : var(--color-on-primary);
} */