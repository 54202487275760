.rain-button{
	position: fixed;
	bottom:0;
	right: 0;
	width: 50px;
	height: 50px;
	cursor: pointer;
    z-index: 100;
    background-color: transparent;
    border: none;
}

.rain-button img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rain-button.active{
    /* turn infinitely */
    animation: spin 3s infinite linear;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.rain-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Behind other elements */
    overflow: hidden; /* Hide overflow */   
}

.snowflake {
    position: absolute;
    background: #ffffff; /* Snowflake color */
    border-radius: 50%;
    pointer-events: none; /* So it doesn't interfere with other elements */
    animation-duration: 5s; /* Adjust speed of snowfall here */
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.leaf{
    position: absolute;
    border-radius: 50%;
    pointer-events: none; /* So it doesn't interfere with other elements */
    animation-duration: 5s; /* Adjust speed of fall here */
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-size:cover;
}

.rain_anim1{
	animation-name: fall1;
}

.rain_anim2{
	animation-name: fall2;
}

.rain_anim3{
	animation-name: fall3;
}

.snowflake, .blurry {
    filter: blur(1px); /* Add a slight blur effect */
}

@keyframes fall1 {
    0% {
        transform: translate(0, -20px) rotate(0deg);
    }
    5% {
        transform: translate(-5px, calc(0.05 * var(--page-height))) rotate(-5deg);
    }
    10% {
        transform: translate(10px, calc(0.1 * var(--page-height))) rotate(5deg);
    }
    15% {
        transform: translate(-10px, calc(0.15 * var(--page-height))) rotate(-10deg);
    }
    20% {
        transform: translate(15px, calc(0.2 * var(--page-height))) rotate(10deg);
    }
    25% {
        transform: translate(-20px, calc(0.25 * var(--page-height))) rotate(-15deg);
    }
    30% {
        transform: translate(20px, calc(0.3 * var(--page-height))) rotate(15deg);
    }
    35% {
        transform: translate(-15px, calc(0.35 * var(--page-height))) rotate(-10deg);
    }
    40% {
        transform: translate(10px, calc(0.4 * var(--page-height))) rotate(5deg);
    }
    45% {
        transform: translate(-5px, calc(0.45 * var(--page-height))) rotate(-5deg);
    }
    50% {
        transform: translate(0px, calc(0.5 * var(--page-height))) rotate(0deg);
    }
    55% {
        transform: translate(5px, calc(0.55 * var(--page-height))) rotate(5deg);
    }
    60% {
        transform: translate(-10px, calc(0.6 * var(--page-height))) rotate(-10deg);
    }
    65% {
        transform: translate(15px, calc(0.65 * var(--page-height))) rotate(10deg);
    }
    70% {
        transform: translate(-20px, calc(0.7 * var(--page-height))) rotate(-15deg);
    }
    75% {
        transform: translate(20px, calc(0.75 * var(--page-height))) rotate(15deg);
    }
    80% {
        transform: translate(-15px, calc(0.8 * var(--page-height))) rotate(-10deg);
    }
    85% {
        transform: translate(10px, calc(0.85 * var(--page-height))) rotate(5deg);
    }
    90% {
        transform: translate(-5px, calc(0.9 * var(--page-height))) rotate(-5deg);
    }
    95% {
        transform: translate(0px, calc(0.96 * var(--page-height))) rotate(0deg);
    }
    100% {
        transform: translate(-20px, calc(1.02 * var(--page-height))) rotate(0deg);
    }
}

@keyframes fall2 {
    0% {
        transform: translate(-10px, -20px) rotate(0deg);
    }
    5% {
        transform: translate(-5px, calc(0.05 * var(--page-height))) rotate(5deg);
    }
    10% {
        transform: translate(15px, calc(0.1 * var(--page-height))) rotate(-10deg);
    }
    15% {
        transform: translate(-20px, calc(0.15 * var(--page-height))) rotate(10deg);
    }
    20% {
        transform: translate(20px, calc(0.2 * var(--page-height))) rotate(-5deg);
    }
    25% {
        transform: translate(-15px, calc(0.25 * var(--page-height))) rotate(0deg);
    }
    30% {
        transform: translate(10px, calc(0.3 * var(--page-height))) rotate(15deg);
    }
    35% {
        transform: translate(-5px, calc(0.35 * var(--page-height))) rotate(-10deg);
    }
    40% {
        transform: translate(0px, calc(0.4 * var(--page-height))) rotate(5deg);
    }
    45% {
        transform: translate(5px, calc(0.45 * var(--page-height))) rotate(-15deg);
    }
    50% {
        transform: translate(10px, calc(0.5 * var(--page-height))) rotate(0deg);
    }
    55% {
        transform: translate(-15px, calc(0.55 * var(--page-height))) rotate(10deg);
    }
    60% {
        transform: translate(20px, calc(0.6 * var(--page-height))) rotate(-10deg);
    }
    65% {
        transform: translate(-20px, calc(0.65 * var(--page-height))) rotate(15deg);
    }
    70% {
        transform: translate(15px, calc(0.7 * var(--page-height))) rotate(-5deg);
    }
    75% {
        transform: translate(-10px, calc(0.75 * var(--page-height))) rotate(0deg);
    }
    80% {
        transform: translate(5px, calc(0.8 * var(--page-height))) rotate(-15deg);
    }
    85% {
        transform: translate(0px, calc(0.85 * var(--page-height))) rotate(10deg);
    }
    90% {
        transform: translate(-5px, calc(0.9 * var(--page-height))) rotate(-10deg);
    }
    95% {
        transform: translate(10px, calc(0.96 * var(--page-height))) rotate(15deg);
    }
    100% {
        transform: translate(-20px, calc(1.02 * var(--page-height))) rotate(10deg);
    }
}

@keyframes fall3 {
    0% {
        transform: translate(10px, -20px) rotate(0deg);
    }
    5% {
        transform: translate(15px, calc(0.05 * var(--page-height))) rotate(-5deg);
    }
    10% {
        transform: translate(-20px, calc(0.1 * var(--page-height))) rotate(5deg);
    }
    15% {
        transform: translate(20px, calc(0.15 * var(--page-height))) rotate(-10deg);
    }
    20% {
        transform: translate(-15px, calc(0.2 * var(--page-height))) rotate(10deg);
    }
    25% {
        transform: translate(10px, calc(0.25 * var(--page-height))) rotate(-5deg);
    }
    30% {
        transform: translate(-5px, calc(0.3 * var(--page-height))) rotate(0deg);
    }
    35% {
        transform: translate(0px, calc(0.35 * var(--page-height))) rotate(5deg);
    }
    40% {
        transform: translate(5px, calc(0.4 * var(--page-height))) rotate(-10deg);
    }
    45% {
        transform: translate(10px, calc(0.45 * var(--page-height))) rotate(15deg);
    }
    50% {
        transform: translate(-15px, calc(0.5 * var(--page-height))) rotate(10deg);
    }
    55% {
        transform: translate(20px, calc(0.55 * var(--page-height))) rotate(-5deg);
    }
    60% {
        transform: translate(-20px, calc(0.6 * var(--page-height))) rotate(-10deg);
    }
    65% {
        transform: translate(15px, calc(0.65 * var(--page-height))) rotate(15deg);
    }
    70% {
        transform: translate(-10px, calc(0.7 * var(--page-height))) rotate(-15deg);
    }
    75% {
        transform: translate(5px, calc(0.75 * var(--page-height))) rotate(0deg);
    }
    80% {
        transform: translate(0px, calc(0.8 * var(--page-height))) rotate(5deg);
    }
    85% {
        transform: translate(-5px, calc(0.85 * var(--page-height))) rotate(10deg);
    }
    90% {
        transform: translate(10px, calc(0.9 * var(--page-height))) rotate(-10deg);
    }
    95% {
        transform: translate(-15px, calc(0.96 * var(--page-height))) rotate(15deg);
    }
    100% {
        transform: translate(20px, calc(1.02 * var(--page-height))) rotate(20deg);
    }
}
