.image_slider{
    position: relative;
    height: 27rem;
    display: block;
    background-size: cover;
    background-position: center;
}

.slides_container{
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
}


.slider_arrow.right_arrow{
    right: 25px;
}

.slider_arrow{
    position: absolute;
    color: var(--color-on-primary);
    top: calc(50% - ( 3rem / 2 ));
    text-shadow: 0 0 14px rgb(0 0 0);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    z-index: 100;
    font-size: 3rem;
}

.slider_arrow.left_arrow{
    left: 25px;
}

.slider_arrow:hover{
    font-size: 3.5rem;
    text-shadow: 0 0 16px 10px rgb(0 0 0 0.5);
    top: calc(50% - ( 3.5rem / 2 ));
    transition: all 0.2s ease-in-out;
}

.slider_dots{
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.slider_dot{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--color-on-primary);
    box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 58%);
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.slider_dot:hover{
    width: 18px;
    height: 18px;
    transition: all 0.2s ease-in-out;
}

.slider_dot.active_dot{
    width: 22px;
    height: 22px;
    transition: all 0.2s ease-in-out;
}




@media only screen and (max-width: 900px) {
    .image_slider{
        height: 25rem;
    }
}

@media only screen and (max-width: 700px) {
    .image_slider{
        height: 20rem;
    }
}


@media only screen and (max-width: 600px) {
    .image_slider{
        height: 16rem;
    }
}


@media only screen and (max-width: 500px) {
    .image_slider{
        height: 15rem;
    }
}

@media only screen and (max-width: 400px) {
    .image_slider{
        height: 10rem;
    }
}


@media only screen and (min-width: 1400px) {
    ul:nth-child(1) .image_slider{
        height: 20rem;
    }

    ul:nth-child(2) .image_slider{
        height: 15rem;
    }

    ul:nth-child(1) .image_slider_container{
        width: calc((1120px / 2) - 40px * 1);
        height: 20rem;
    }

    ul:nth-child(2) .image_slider_container{
        width: calc((1120px / 3) - 40px * 2);
        height: 15rem;
    }

    ul:nth-child(3) .image_slider_container{
        width: calc((1120px / 4) - 40px * 1);
        height: 15rem;
    }
}