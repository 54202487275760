.footer{
    background-color: #202020;
    color: #ffffff;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer_section{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 6rem;
    max-width: 1120px;
}

.footer_link{
    color: lightblue;
    text-decoration: none;
    text-align: center;
    background: linear-gradient(0deg, var(--background-tertiary-color), var(--background-tertiary-color)) no-repeat right bottom / 0 var(--bg-h);
    transition: background-size 0.35s, color 0.35s ease-in-out;
    --bg-h: 100%;
    z-index: 3;
}

.footer p {
    z-index: 3;
}


.footer_link:where(:hover, :focus-visible) {
    background-size: 100% var(--bg-h);
    background-position-x: left;
    color: var(--color-on-tertiary);
}

@media only screen and (max-width: 1024px) {
    .footer_section {
        flex-direction: column;
        padding: 0rem 2rem;
    }

    .footer_link {
        margin-bottom: 1rem;
    }
}