video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    z-index: -1;
}

.link_about {
    color: lightblue;
    text-decoration: none;
    background: linear-gradient(0deg, var(--background-tertiary-color), var(--background-tertiary-color)) no-repeat right bottom / 0 var(--bg-h);
    transition: background-size 0.35s, color 0.35s ease-in-out;
    --bg-h: 100%;
}

.link_about:where(:hover, :focus-visible) {
    background-size: 100% var(--bg-h);
    background-position-x: left;
    color: var(--color-on-tertiary);
}

.single_line_text {
    display: inline;
}

.hero-container {
    /*background: url('/public/images/img-cliff-2.jpg') center center/cover no-repeat;*/
    background: var(--background-tertiary-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: cover;
    padding: 0 4rem 8rem 4rem;
}

body.dark .hero-container {
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.7);
}

.about_container {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.815);
    backdrop-filter: blur(8px);
    margin-top: 8rem;
    padding: 2rem 1rem;
    width: 90%;
    max-width: 1120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    z-index: 3;
    width: 100%;
}

body.dark .about_container {
    background-color: rgba(0, 0, 0, 0.8);
    /* slight blurry white shadow around it*/
    box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.1);
    width: 100%;
}

.about_img_container {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.me_englobe{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background: url('/public/images/charly-flu.jpg') center center/cover no-repeat;
}

.me_img {
    width: 14rem;
    height: 14rem;
    display: block;
    border-radius: 50%;
    box-shadow: 0 6px 20px rgba(146, 146, 146, 0.95);
    transition: all 0.3s;
}

.about_text {
    width: fit-content;
}

.hero-container h1,
p {
    color: var(--color-on-primary);
    display: flex;
    flex-direction: column;
}

.hero-container h1 {
    font-size: 2rem;
    padding-bottom: 0.5rem;
}

.hero-container p {
    font-size: 1.2rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    line-height: 1.4rem;
}

.hero-container p.warning {
    color:crimson;
    font-weight: bolder;
    text-shadow: 0 0 10px crimson;
}

.hero-btns {
    margin-top: 1rem;
}

.btn--primary {
    margin-left: 0.5rem;
}

.fa-solid {
    margin-left: 0.5rem;
}
@media screen and (min-width: 1400px){
    .about_container{
        padding: 2.5rem 1rem;
    }

    .me_img {
        width: 17rem;
        height: 17rem;
    }
}

@media screen and (max-width: 960px) {
    .me_img {
        width: 13rem;
        height: 13rem;
    }

    .me_englobe{
        margin-bottom: 3rem;
    }

    .hero-container {
        padding: 0 4rem 4rem 4rem;
    }

    .btn {
        border-radius: 6px;
    }

    .hero-container h1 {
        margin-top: 0;
        font-size: 2.2rem;
        text-align: center;
    }

    .hero-container p {
        text-align: center;
        font-size: 1.3rem;
    }

    .btn-mobile {
        display: flex;
        text-decoration: none;
        justify-content: center;
        align-items: center;
    }

    .btn {
        width: 95%;
        margin: 0;
        margin-top: 0.5rem;
    }

    .about_container {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .about_img_container {
        height: 25%;
    }

    .inv-mobile {
        display: none;
    }
}

@media screen and (max-width: 750px) {
    .hero-container {
        padding: 0 2rem 4rem 2rem;
    }
}

@media screen and (max-width: 550px) {
    .hero-container h1 {
        font-size: 2rem;
    }
    
    .hero-container p {
        font-size: 1.2rem;
    }

    .me_img {
        width: 9rem;
        height: 9rem;
    }

    .me_englobe{
        margin-bottom: 0;
    }
}