.skills h1{
    font-size: 2.5rem;
    color: var(--color-on-secondary);
    margin-bottom: 2rem;
}
.skills{
    background: var(--background-secondary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.skills_container{
    max-width: 1120px;
    color: var(--color-on-secondary);
    padding: 2rem;

}

.bar_skills{
    height: 8vw;
    width: 100%;
    margin: -4vw 0px -4vw 0px;
    transform: rotate(-4deg) scale(1.2, 1);
    background-color: var(--background-secondary-color);
}

@media screen and (min-width: 960px) {
    .bar_skills {
        border-top: 1px solid var(--color-on-secondary);

    }
}

.skill_img{
    display: block;
    width: 100%;
    max-width: 60%;
    height: 100%;
    max-height: 60%;
}

.skill .img_container{
    width: 6rem;
    height: 6rem;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    margin: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-30deg) skew(25deg) scale(.8);
    transition: 0.5s;
    overflow: visible;
    background-color: var(--background-secondary-color) ;

}

body.dark .skill .img_container{
    box-shadow: 0 6px 20px rgba(255, 255, 255, 0.15);
}

.skill{
    color : var(--tertiary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 2rem;
    text-align: center;
    z-index: 3;
}

.skill .img_container img { 
    position: absolute;
    width: 100%;
    transition: 0.5s;
}

.skill .img_container img:nth-child(1){
    filter: blur(10px) brightness(0.5); /* Apply a blurry effect */
    opacity: 0.8; /* Adjust the opacity to your preference */
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.8); /* Add a thick, blurry shadow */
    transition: all 0.5s ease-in-out;
}
/*
.skill .img_container:hover img:nth-child(1){
    filter: brightness(0.5);
    transition: all 0.5s ease-in-out;
   
} */

.skill .img_container:hover img:nth-child(5){
    transform: translate(80px, -80px);
    opacity: 1;
}
.skill .img_container:hover img:nth-child(4){
    transform: translate(60px, -60px);
    opacity: .8;
}
.skill .img_container:hover img:nth-child(3){
    transform: translate(40px, -40px);
    opacity: .6;
}
.skill .img_container:hover img:nth-child(2){
    transform: translate(20px, -20px);
    opacity: .4;
}

@media screen and (max-width: 960px) {
    .skill{
        margin: 0;
    }
    .skill .img_container{
        margin: 0 1rem;
    }
}